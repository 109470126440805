<template>
  <div class="simuPayLogin">
    <div class="head">
      <div
        class="main-card"
        style="height: 100%; display: flex; align-items: center"
      >
        <svg
          v-if="type == 3"
          width="306px"
          height="20px"
          viewBox="0 0 356 40"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>logo_cn</title>
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="logo_cn">
              <rect id="bg" x="0" y="0" width="356" height="40"></rect>
              <g
                id="lianlianglobal_cn"
                transform="translate(0.000000, 1.000000)"
                fill="#0E59F0"
                fill-rule="nonzero"
              >
                <path
                  d="M30.568,25.3861818 C30.568,24.5956364 30.824,23.9709091 31.3498182,23.4763636 C32.4567273,22.4370909 34.7127273,21.9309091 38.2472727,21.9309091 C38.9025455,21.9309091 39.5970909,21.9483636 40.368,21.9832727 L40.368,28.0174545 C39.4305455,28.2829091 37.6705455,28.5483636 36.0698182,28.5483636 C33.56,28.5483636 30.568,27.9992727 30.568,25.3861818 Z M35.4683636,12.24 C33.1825455,12.24 30.8145455,12.784 29.2894545,13.6610909 L28.8996364,13.8843636 L28.8989091,17.3309091 L28.9898182,17.2538182 C29.0109091,17.2356364 31.1789091,15.4421818 35.3025455,15.2392727 C37.2574545,15.1432727 38.6778182,15.5025455 39.5243636,16.3054545 C40.1069091,16.8589091 40.4021818,17.6225455 40.4021818,18.5745455 L40.4021818,19.1018182 C36.1621818,18.9549091 31.5185455,19.1170909 29.1345455,21.3854545 C28.0741818,22.3949091 27.5367273,23.7418182 27.5367273,25.3890909 C27.5367273,29.1810909 30.728,31.4443636 36.0734545,31.4443636 C38.4545455,31.4443636 41.0021818,31.0341818 42.8887273,30.3454545 L43.4,30.1592727 L43.4,18.4625455 C43.4,14.3338182 40.7316364,12.24 35.4683636,12.24 L35.4683636,12.24 Z"
                  id="Fill-1"
                ></path>
                <path
                  d="M3.25236364,27.3890909 L3.25236364,0.667636364 L0.220363636,0.667636364 L0.220363636,27.4683636 C0.220363636,29.4530909 1.83854545,31.0669091 3.82690909,31.0669091 L19.0312727,31.0669091 L19.0312727,28.0429091 L3.90545455,28.0429091 C3.54545455,28.0429091 3.25236364,27.7498182 3.25236364,27.3890909"
                  id="Fill-2"
                ></path>
                <path
                  d="M55.2494545,12.24 C53.0225455,12.24 51.1090909,12.7229091 49.4021818,13.7163636 L49.4021818,13.2043636 L46.4414545,13.2043636 L46.4414545,31.0669091 L49.4734545,31.0669091 L49.4843636,17.7578182 C49.4843636,16.9963636 51.6407273,15.2647273 55.192,15.2647273 C59.4945455,15.2647273 60.3985455,17.5192727 60.3985455,19.4094545 L60.3869091,31.0123636 L60.3869091,31.0669091 L63.4290909,31.0669091 L63.4290909,19.2065455 C63.4290909,14.8443636 60.3716364,12.24 55.2494545,12.24"
                  id="Fill-3"
                ></path>
                <polygon
                  id="Fill-4"
                  points="21.4647273 31.0705455 24.4952727 31.0705455 24.4952727 13.0254545 21.4647273 13.0254545"
                ></polygon>
                <polygon
                  id="Fill-5"
                  points="21.4647273 11.1578182 24.4952727 11.1578182 24.4952727 6.82109091 21.4647273 6.82109091"
                ></polygon>
                <path
                  d="M99.2458182,25.3861818 C99.2458182,24.5956364 99.5010909,23.9709091 100.026909,23.4763636 C101.133818,22.4370909 103.389818,21.9309091 106.924364,21.9309091 C107.580364,21.9309091 108.274182,21.9483636 109.045091,21.9832727 L109.045091,28.0174545 C108.108364,28.2829091 106.347636,28.5483636 104.746909,28.5483636 C102.237091,28.5483636 99.2458182,27.9992727 99.2458182,25.3861818 Z M104.145455,12.24 C101.859636,12.24 99.4923636,12.784 97.9665455,13.6610909 L97.5767273,13.8843636 L97.5767273,17.3309091 L97.6676364,17.2538182 C97.6887273,17.2356364 99.8567273,15.4421818 103.980364,15.2392727 C105.934545,15.1432727 107.355636,15.5025455 108.201455,16.3054545 C108.784,16.8589091 109.08,17.6225455 109.08,18.5745455 L109.08,19.1018182 C104.839273,18.9549091 100.195636,19.1170909 97.8123636,21.3854545 C96.7512727,22.3949091 96.2138182,23.7418182 96.2138182,25.3890909 C96.2138182,29.1810909 99.4050909,31.4443636 104.751273,31.4443636 C107.131636,31.4443636 109.68,31.0341818 111.565818,30.3454545 L112.077091,30.1592727 L112.077091,18.4625455 C112.077091,14.3338182 109.408727,12.24 104.145455,12.24 L104.145455,12.24 Z"
                  id="Fill-6"
                ></path>
                <path
                  d="M71.9294545,27.3890909 L71.9294545,0.667636364 L68.8974545,0.667636364 L68.8974545,27.4683636 C68.8974545,29.4530909 70.5156364,31.0669091 72.504,31.0669091 L87.7083636,31.0669091 L87.7083636,28.0429091 L72.5832727,28.0429091 C72.2225455,28.0429091 71.9294545,27.7498182 71.9294545,27.3890909"
                  id="Fill-7"
                ></path>
                <path
                  d="M123.926545,12.24 C121.700364,12.24 119.786182,12.7229091 118.079273,13.7163636 L118.079273,13.2043636 L115.118545,13.2043636 L115.118545,31.0669091 L118.150545,31.0669091 L118.161455,17.7578182 C118.161455,16.9963636 120.317818,15.2647273 123.869091,15.2647273 C128.172364,15.2647273 129.075636,17.5192727 129.075636,19.4094545 L129.064,31.0123636 L129.064,31.0669091 L132.106182,31.0669091 L132.106182,19.2065455 C132.106182,14.8443636 129.048727,12.24 123.926545,12.24"
                  id="Fill-8"
                ></path>
                <polygon
                  id="Fill-9"
                  points="90.1418182 31.0705455 93.1730909 31.0705455 93.1730909 13.0254545 90.1418182 13.0254545"
                ></polygon>
                <polygon
                  id="Fill-10"
                  points="90.1418182 11.1578182 93.1730909 11.1578182 93.1730909 6.82109091 90.1418182 6.82109091"
                ></polygon>
                <path
                  d="M213.556364,25.3861818 C213.556364,24.5956364 213.811636,23.9709091 214.337455,23.4763636 C215.445091,22.4370909 217.701091,21.9309091 221.234909,21.9309091 C221.890182,21.9309091 222.584727,21.9483636 223.356364,21.9832727 L223.356364,28.0174545 C222.418182,28.2829091 220.658182,28.5483636 219.057455,28.5483636 C216.547636,28.5483636 213.556364,27.9992727 213.556364,25.3861818 Z M218.456727,12.24 C216.170909,12.24 213.802909,12.784 212.277091,13.6610909 L211.887273,13.8843636 L211.887273,17.3309091 L211.978182,17.2538182 C211.999273,17.2356364 214.166545,15.4421818 218.290909,15.2392727 C220.245091,15.1432727 221.666182,15.5025455 222.512,16.3054545 C223.095273,16.8589091 223.390545,17.6225455 223.390545,18.5745455 L223.390545,19.1018182 C219.147636,18.9549091 214.506909,19.1178182 212.122182,21.3854545 C211.062545,22.3949091 210.524364,23.7418182 210.524364,25.3890909 C210.524364,29.1810909 213.715636,31.4443636 219.061091,31.4443636 C221.442909,31.4443636 223.990545,31.0341818 225.876364,30.3454545 L226.387636,30.1592727 L226.387636,18.4625455 C226.387636,14.3338182 223.72,12.24 218.456727,12.24 L218.456727,12.24 Z"
                  id="Fill-11"
                ></path>
                <polygon
                  id="Fill-12"
                  points="166.317818 31.0712727 169.348364 31.0712727 169.348364 0.667636364 166.317818 0.667636364"
                ></polygon>
                <polygon
                  id="Fill-13"
                  points="229.429091 31.0712727 232.460364 31.0712727 232.460364 0.667636364 229.429091 0.667636364"
                ></polygon>
                <path
                  d="M185.677091,21.8727273 C185.677091,26.288 183.952,28.5272727 180.549818,28.5272727 C177.146909,28.5272727 175.421818,26.288 175.421818,21.8727273 C175.421818,17.4567273 177.146909,15.2174545 180.549818,15.2174545 C183.952,15.2174545 185.677091,17.4567273 185.677091,21.8727273 Z M180.549818,12.2407273 C178.096727,12.2407273 172.389818,13.1796364 172.389818,21.8727273 C172.389818,30.5658182 178.096727,31.504 180.549818,31.504 C183.002909,31.504 188.709091,30.5658182 188.709091,21.8727273 C188.709091,13.1796364 183.002909,12.2407273 180.549818,12.2407273 L180.549818,12.2407273 Z"
                  id="Fill-14"
                ></path>
                <path
                  d="M205.065455,21.8894545 C205.065455,26.2421818 202.776,28.4494545 198.260364,28.4494545 C197.368,28.4494545 196.198545,28.2690909 194.782545,27.9127273 L194.782545,15.9330909 C195.751273,15.5316364 196.921455,15.328 198.260364,15.328 C202.776,15.328 205.065455,17.536 205.065455,21.8894545 Z M198.260364,12.3032727 C197.013818,12.3032727 195.844364,12.4516364 194.782545,12.7447273 L194.782545,0.667636364 L191.750545,0.667636364 L191.750545,30.2247273 L191.835636,30.2247273 C193.982545,30.8741818 196.245818,31.4749091 198.260364,31.4749091 C204.512,31.4749091 208.097455,27.9810909 208.097455,21.8894545 C208.097455,15.7970909 204.512,12.3032727 198.260364,12.3032727 L198.260364,12.3032727 Z"
                  id="Fill-15"
                ></path>
                <path
                  d="M160.670545,14.9330909 L151.831273,14.9330909 L151.831273,17.9585455 L159.598545,17.9585455 C159.954909,17.9585455 160.245091,18.248 160.245091,18.6029091 L160.245091,27.3112727 C158.378182,28.0545455 153.642909,29.4981818 149.203636,27.2567273 C146.565818,25.9243636 144.584,22.92 143.902545,19.2189091 C143.077091,14.7403636 144.282182,10.2058182 147.127273,7.09018182 C149.344,4.66181818 152.147636,3.52436364 155.458909,3.70545455 C158.182545,3.856 160.568,4.88218182 161.746909,5.48 L161.826909,5.52072727 L161.826909,2.16654545 L161.793455,2.152 C159.114909,1.03054545 156.211636,0.528727273 153.616727,0.738181818 C150.184727,1.01527273 147.247273,2.46690909 144.885818,5.05309091 C141.407273,8.86327273 139.925091,14.3636364 140.92,19.7658182 C141.771636,24.3905455 144.357091,28.2 147.834909,29.9556364 C149.861091,30.9781818 152.094545,31.4967273 154.474182,31.4967273 C158.874182,31.4967273 162.324364,29.7069091 162.469091,29.6305455 L163.276364,29.2050909 L163.276364,17.5861818 C163.276364,16.1236364 162.107636,14.9330909 160.670545,14.9330909"
                  id="Fill-16"
                ></path>
                <path
                  d="M324.726545,27.5141818 C324.726545,27.8341818 324.466909,28.0938182 324.146909,28.0938182 L307.818182,28.0938182 C307.497455,28.0938182 307.237818,27.8341818 307.237818,27.5141818 L307.237818,10.4276364 C307.237818,10.1076364 307.497455,9.848 307.818182,9.848 L324.146909,9.848 C324.466909,9.848 324.726545,10.1076364 324.726545,10.4276364 L324.726545,27.5141818 Z M325.842182,6.82763636 L306.122182,6.82763636 C305.072,6.82763636 304.217455,7.68218182 304.217455,8.73236364 L304.217455,29.2094545 C304.217455,30.2596364 305.072,31.1141818 306.122182,31.1141818 L325.842182,31.1141818 C326.893091,31.1141818 327.747636,30.2596364 327.747636,29.2094545 L327.747636,8.73236364 C327.747636,7.68218182 326.893091,6.82763636 325.842182,6.82763636 L325.842182,6.82763636 Z"
                  id="Fill-17"
                ></path>
                <polyline
                  id="Fill-18"
                  points="308.443636 14.3192727 314.465455 14.3192727 314.465455 17.3941818 308.985455 17.3941818 308.985455 20.4152727 314.465455 20.4152727 314.465455 23.6218182 308.443636 23.6218182 308.443636 26.6421818 323.521455 26.6421818 323.521455 23.6218182 322.323636 23.6218182 322.323636 21.408 319.302545 21.408 319.302545 23.6218182 317.485818 23.6218182 317.485818 20.4152727 322.991273 20.4152727 322.991273 17.3941818 317.485818 17.3941818 317.485818 14.3192727 323.521455 14.3192727 323.521455 11.2989091 308.443636 11.2989091 308.443636 14.3192727"
                ></polyline>
                <polygon
                  id="Fill-19"
                  points="341.132364 10.2232727 354.426909 10.2232727 354.426909 7.20290909 341.132364 7.20290909"
                ></polygon>
                <path
                  d="M339.629091,20.3541818 C339.629091,19.0261818 339.371636,17.7789091 338.189091,16.8414545 C339.371636,16.0094545 339.629091,14.5978182 339.629091,13.3294545 L339.629091,9.54909091 C339.629091,8.03490909 338.401455,6.80654545 336.886545,6.80654545 L333.321455,6.80654545 C331.920727,6.80654545 330.784727,7.94254545 330.784727,9.34254545 L330.784727,31.0705455 L333.806545,31.0705455 L333.806545,10.4065455 C333.806545,10.0865455 334.065455,9.82690909 334.386182,9.82690909 L336.027636,9.82690909 C336.348364,9.82690909 336.607273,10.0865455 336.607273,10.4065455 L336.607273,13.4734545 C336.607273,14.4996364 335.775273,15.3316364 334.749091,15.3316364 L334.746182,15.3316364 L334.746182,18.352 L334.749091,18.352 C335.775273,18.352 336.607273,19.184 336.607273,20.2101818 L336.607273,23.1658182 C336.607273,23.4858182 336.348364,23.7454545 336.027636,23.7454545 L334.821818,23.7454545 L334.821818,26.7658182 L336.885818,26.7658182 C338.400727,26.7658182 339.629091,25.5374545 339.629091,24.0225455 L339.629091,20.3541818"
                  id="Fill-20"
                ></path>
                <path
                  d="M340.277818,14.3912727 L340.277818,17.4116364 L346.125091,17.4116364 L346.168,27.4661818 C346.169455,27.7869091 345.909091,28.048 345.588364,28.048 L344.162182,28.048 L344.162182,31.0683636 L346.624727,31.0683636 C348.042182,31.0683636 349.191273,29.9192727 349.191273,28.5025455 L349.145455,17.4116364 L355.282182,17.3854545 L355.282182,14.3650909 L340.277818,14.3912727"
                  id="Fill-21"
                ></path>
                <path
                  d="M344.565818,18.9192727 L341.542545,18.9192727 L341.542545,19.4632727 C341.538182,22.1141818 341.056727,26.8894545 339.750545,29.1818182 L339.629091,29.3956364 L342.976727,29.3956364 L343.012364,29.3054545 C344.519273,25.4858182 344.562909,20.0734545 344.562909,19.4698182 L344.565818,18.9192727"
                  id="Fill-22"
                ></path>
                <polyline
                  id="Fill-23"
                  points="350.029818 18.9192727 352.154909 29.3956364 355.282182 29.3956364 353.154182 18.9192727 350.029818 18.9192727"
                ></polyline>
                <polygon
                  id="Fill-24"
                  points="250.711273 12.7061818 253.735273 12.7061818 253.735273 7.88945455 250.711273 7.88945455"
                ></polygon>
                <path
                  d="M262.600727,6.80581818 L259.384727,6.80581818 L258.634182,8.87345455 L255.065455,8.87345455 L255.065455,11.8967273 L257.537455,11.8967273 L255.485818,17.5498182 C255.252364,18.1956364 255.478545,18.9461818 256.104,19.3469091 C256.357091,19.5083636 256.659636,19.576 256.96,19.576 L264.013818,19.576 L264.013818,21.848 L255.262545,21.848 L255.262545,24.8705455 L264.013818,24.8705455 L264.013818,28.0501818 L254.658909,28.0501818 C254.146182,28.0501818 253.731636,27.6349091 253.731636,27.1229091 L253.731636,13.9912727 L250.706909,13.9912727 L250.706909,27.072 C250.706909,29.2814545 252.498909,31.0734545 254.708364,31.0734545 L274.432727,31.0734545 L274.432727,28.0501818 L267.037818,28.0501818 L267.037818,24.8705455 L273.956364,24.8705455 L273.956364,21.848 L267.037818,21.848 L267.037818,19.576 L273.956364,19.576 L273.956364,16.5534545 L267.037818,16.5534545 L267.037818,12.672 L264.013818,12.672 L264.013818,16.5534545 L259.063273,16.5534545 L260.753455,11.8967273 L274.432727,11.8967273 L274.432727,8.87345455 L261.850909,8.87345455 L262.600727,6.80581818"
                  id="Fill-25"
                ></path>
                <polygon
                  id="Fill-26"
                  points="277.477091 12.7061818 280.501091 12.7061818 280.501091 7.88945455 277.477091 7.88945455"
                ></polygon>
                <path
                  d="M289.366545,6.80581818 L286.150545,6.80581818 L285.4,8.87345455 L281.831273,8.87345455 L281.831273,11.8967273 L284.303273,11.8967273 L282.250909,17.5498182 C282.018182,18.1956364 282.244364,18.9461818 282.869818,19.3469091 C283.122909,19.5083636 283.425455,19.576 283.725091,19.576 L290.779636,19.576 L290.779636,21.848 L282.028364,21.848 L282.028364,24.8705455 L290.779636,24.8705455 L290.779636,28.0501818 L281.424727,28.0501818 C280.912727,28.0501818 280.496727,27.6349091 280.496727,27.1229091 L280.496727,13.9912727 L277.473455,13.9912727 L277.473455,27.072 C277.473455,29.2814545 279.264727,31.0734545 281.474182,31.0734545 L301.198545,31.0734545 L301.198545,28.0501818 L293.803636,28.0501818 L293.803636,24.8705455 L300.722182,24.8705455 L300.722182,21.848 L293.803636,21.848 L293.803636,19.576 L300.722182,19.576 L300.722182,16.5534545 L293.803636,16.5534545 L293.803636,12.672 L290.779636,12.672 L290.779636,16.5534545 L285.829091,16.5534545 L287.519273,11.8967273 L301.198545,11.8967273 L301.198545,8.87345455 L288.616727,8.87345455 L289.366545,6.80581818"
                  id="Fill-27"
                ></path>
              </g>
            </g>
          </g>
        </svg>
        <img style="width: 120px" v-if="type == 1" :src="payoneerImg" alt="" />
        <img style="width: 100px" v-if="type == 2" :src="pingpongImg" alt="" />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
      "
    >
      <div v-if="type == 1" class="main-card">
        <div class="payoneer-cell">
          <div>
            <img :src="payoneerImg" alt="" />
          </div>
          <div
            style="
              color: #797e87;
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              margin: 20px 0;
            "
          >
            登录已连接Shopee Philippines <br />至您的Payoneer派安盈账户
          </div>
          <div style="display: flex; justify-content: center; margin-top: 20px">
            <el-form
              ref="form"
              label-position="top"
              :model="form"
              label-width="80px"
              size="small"
            >
              <el-form-item>
                <el-input
                  placeholder="输入手机号"
                  style="min-width: 300px"
                  maxlength="11"
                  v-model="form.account"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="输入登录密码"
                  show-password
                  style="min-width: 300px"
                  v-model="form.password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="
                    background: #ee4d2d;
                    border: none;
                    min-width: 300px;
                    margin-top: 20px;
                  "
                  type="primary"
                  @click="onSubmit"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div v-if="type == 2" class="main-card">
        <div class="pingpong-cell">
          <div>
            <img style="width: 100px" :src="pingpongImg" alt="" />
          </div>
          <div style="display: flex; justify-content: center; margin-top: 20px">
            <el-form
              ref="form"
              label-position="top"
              :model="form"
              label-width="80px"
              size="small"
            >
              <el-form-item>
                <el-input
                  placeholder="输入您的手机号"
                  style="min-width: 240px"
                  v-model="form.account"
                  maxlength="11"
                >
                  <template slot="prepend">+86</template></el-input
                >
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="输入登录密码"
                  show-password
                  style="min-width: 240px"
                  v-model="form.password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="
                    background: #1fa0e8;
                    border: none;
                    min-width: 240px;
                    margin-top: 20px;
                  "
                  type="primary"
                  round
                  @click="onSubmit"
                  >立即登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="cell main-card" v-if="type == 3">
        <div style="height: 100px; width: 200px"></div>
        <div style="font-size: 20px; text-align: center">
          登录LianLian Global账号
        </div>
        <div style="font-size: 14px; margin-top: 20px; text-align: center">
          享受极速收款体验
        </div>
        <div style="display: flex; justify-content: center; margin-top: 50px">
          <el-form
            ref="form"
            label-position="top"
            :model="form"
            label-width="80px"
            size="small"
          >
            <el-form-item label="登录账号">
              <el-input
                placeholder="输入手机号"
                style="min-width: 360px"
                v-model="form.account"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="登陆密码">
              <el-input
                placeholder="输入登录密码"
                show-password
                style="min-width: 360px"
                v-model="form.password"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                style="
                  background: #0e59f0;
                  border: none;
                  min-width: 360px;
                  margin-top: 20px;
                "
                type="primary"
                @click="onSubmit"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bindBankAccount } from "@/api/shopIncome.js";
export default {
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      type: "",
      payoneerImg: require("@/assets/img/app/partner-logo.png"),
      pingpongImg: require("@/assets/img/app/pingpong-logo.png"),
    };
  },
  created() {
    if (sessionStorage.BANKINDEX) {
      this.type = sessionStorage.BANKINDEX;
    }
  },
  methods: {
    onSubmit() {
      bindBankAccount({
        ...this.form,
        type: Number(this.type),
      }).then((res) => {
        if (res.code == 1) {
          this.$router.replace("bankAccount");
        }
      });
    },
  },
};
</script>
<style lang="less">
.simuPayLogin {
  background: #f8f9fa;
  height: 100%;

  .head {
    height: 60px;
    border-bottom: 1px solid #eee;
    background: #fff;
  }
  .payoneer-cell {
    width: 450px;
    padding: 20px;
    background: #f2f3f7;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .pingpong-cell {
    width: 360px;
    padding: 40px 0 80px;
    background: #fff;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
  }
  .cell {
    padding: 20px 0 40px;
    // margin-top: 30px;
    background: linear-gradient(180deg, #fff, #f2f8ff);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    // text-align: center;
  }
}
.el-input-group__append,
.el-input-group__prepend {
  background: #f4f5f7;
}
</style>